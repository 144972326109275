import React, { Component, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { SetupInterceptors } from "./services/api/Api";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  
  // useEffect(() => {
  //   if (navigator.geolocation){ 
  //     navigator.geolocation.watchPosition(function (position) {
  //       console.log(position.coords.latitude, position.coords.longitude);
  //     });
  //   } else { 
  //     console.log("Geolocation is not supported by this browser."); 
  //   } 
  //   }, []);

  SetupInterceptors();
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              // name="Login Page"
              // render={(props) => <Login {...props} />}
              component={Login}
            />
            {/* <Route
              exact
              path="/register"
              // name="Register Page"
              // render={(props) => <Register {...props} />}
              component={Register}
            /> */}
            <Route
              exact
              path="/404"
              // name="Page 404"
              // render={(props) => <Page404 {...props} />}
              component={Page404}
            />
            <Route
              exact
              path="/500"
              // name="Page 500"
              // render={(props) => <Page500 {...props} />}
              component={Page500}
            />
            <Route
              path="/"
              // name="Home"
              // render={(props) => <TheLayout {...props} />}
              component={TheLayout}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }

export default App;
