import { sygnet } from "./sygnet";
import { newLogo } from "./new-logo";
import {
  cibCcAmex,
  cibCcMastercard,
  cibCcVisa,
  cibFacebook,
  cibGooglePay,
  cibLinkedin,
  cibPaypal,
  cibSlickpic,
  cibStripe,
  cibTwitter,
  cilColorFill,
  cilCopy,
  cilDataTransferDown,
  cilMenu,
  cilPrint,
  cilSpeak,
  cilStream,
} from "@coreui/icons";
import { cifBr, cifEs, cifFr, cifIn, cifPl, cifUs } from "@coreui/icons";
import {
  cilAirplay,
  cilArrowThickLeft,
  cilArrowThickRight,
  cilArrowRight,
  cilBasket,
  cilBell,
  cilBriefcase,
  cilCart,
  cilCloudDownload,
  cilChartPie,
  cilCheckAlt,
  cilCheckCircle,
  cilChevronDoubleRight,
  cilCut,
  cilColorPalette,
  cilDescription,
  cilEnvelopeOpen,
  cilFile,
  cilFindInPage,
  cilGlobeAlt,
  cilGraph,
  cilHome,
  cilImage,
  cilLayers,
  cilLibrary,
  cilList,
  cilLockLocked,
  cilLoop ,
  cilMagnifyingGlass,
  cilNoteAdd,
  cilNotes,
  cilOptions,
  cilPencil,
  cilLightbulb,
  cilPeople,
  cilPlus,
  cilPuzzle,
  cilQrCode,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilSync,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilZoomIn 
} from "@coreui/icons";

export const icons = Object.assign(
  {
    sygnet,
    newLogo,
  },
  {
    cibCcAmex,
    cibCcMastercard,
    cibCcVisa,
    cibFacebook,
    cibGooglePay,
    cibLinkedin,
    cibPaypal,
    cibSlickpic,
    cibStripe,
    cibTwitter,
  },
  {
    cifBr,
    cifEs,
    cifFr,
    cifIn,
    cifPl,
    cifUs,
  },
  {
    cilAirplay,
    cilArrowThickLeft,
    cilArrowThickRight,
    cilArrowRight,
    cilBasket,
    cilBell,
    cilBriefcase,
    cilCloudDownload,
    cilChartPie,
    cilCheckAlt,
    cilCheckCircle,
    cilChevronDoubleRight,
    cilCart,
    cilCut,
    cilCopy,
    cilColorFill,
    cilColorPalette,
    cilDataTransferDown,
    cilDescription,
    cilEnvelopeOpen,
    cilFile,
    cilFindInPage,
    cilGlobeAlt,
    cilGraph,
    cilHome,
    cilImage,
    cilLayers,
    cilLibrary,
    cilList,
    cilLightbulb,
    cilLockLocked,
    cilLoop,
    cilMenu,
    cilMagnifyingGlass,
    cilNoteAdd,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPlus,
    cilPrint, 
    cilPuzzle,
    cilQrCode,
    cilSettings,
    cilSpeech,
    cilSpeak,
    cilStream,
    cilSpeedometer,
    cilSync,
    cilTrash,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilZoomIn
  }
);
