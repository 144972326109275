const dev = {
  url: {
    
    API_URL: "https://localhost:44349/api/",
  },
};

const prod = {
  url: {
    API_URL: "https://backapi.mafirol.info/api/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

//https://backapi.mafirol.info/api/
//https://localhost:44349/api/
// http://localhost:5000/api/
