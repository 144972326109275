import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { config } from "../../utils/EnvUtil";
import TokenService, { refreshTokenService } from "./TokenService";
import UsuarioLoginResponse from "../../models/UserAuth/UsuarioLoginResponse";


// Estendendo a interface AxiosRequestConfig
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

// //####################  Adicionando lógica para tokens ###################
let isRefreshing = false;
let resfreshSubscribers: any[] = [];

function subscribeTokenResfresh(cb: any){
  resfreshSubscribers.push(cb);
}

function onRefreshed(token: string){
  resfreshSubscribers.map(cb => cb(token));
}

// // ########################################################################


const apiClient = axios.create({
  baseURL: config.url.API_URL,
  headers: { 
    "Content-Type": "application/json", 
    "Authorization" : `Bearer ${TokenService.getAccessToken()}`,
    "Access-Control-Allow-Origin" : "*" ,
    "Access-Control-Allow-Credential":true
  },
});

const SetupInterceptors = () => {
  apiClient.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      // console.log("Pedido: ", config);
      return config;
    },
    function (error) {
      // Do something with request error
      // console.log("Erro no pedido: ", error);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  apiClient.interceptors.response.use(
    (response) => response,    
    async (error: AxiosError) => {
      // const originalRequest = error.config;
      const { config, response } = error;
      const originalRequest = config as CustomAxiosRequestConfig;

      if(error.response?.status === 401 &&  !originalRequest._retry)
      {
        originalRequest._retry = true;



        if(!isRefreshing)
        {
          isRefreshing = true;


            try {
              const oldAccessToken = TokenService.getAccessToken();
              const oldRefreshToken = TokenService.getRefreshToken();
              
              const tokens = {
                accessToken: oldAccessToken,
                refreshToken: oldRefreshToken,
              };   

              const response = await refreshTokenService(tokens);

              const { accessToken, refreshToken, sucesso } = response;
      
              if (sucesso) {
                const { accessToken, refreshToken } = response;
  
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
  
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
  
                // Notify all subscribers with the new token
                onRefreshed(accessToken);
  
                isRefreshing = false;
  
                return axios(originalRequest);
              } else {
                TokenService.removeAccessToken();
                TokenService.removeRefreshToken();
                window.location.href = window.location.protocol + "//" + window.location.host + "/login";
              }

              // localStorage.setItem('accessToken', accessToken);
              // localStorage.setItem('refreshToken', refreshToken);
      
              // // Retry the original request with the new token
              // isRefreshing = false;
              // originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              // return axios(originalRequest);

            } catch (error) {
              TokenService.removeAccessToken();
              TokenService.removeRefreshToken();
              window.location.href = window.location.protocol + "//" + window.location.host + "/login";
            }

            
        }
        else {
          return new Promise((resolve, reject) => {
            subscribeTokenResfresh((token: string) => {
              if (!token) {
                reject(error);
                return;
              }
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              resolve(apiClient(originalRequest));
            });
          });
        }




      }
      else if (error.response?.status === 401) {
        TokenService.removeAccessToken();
        TokenService.removeRefreshToken();
        window.location.href = window.location.protocol + "//" + window.location.host + "/login";
      }
      window.alert("Error performing request");
      return Promise.reject(error);
    }
  );
}

const { get, patch, post, put, delete: destroy } = apiClient;
export { get, patch, post, put, destroy, SetupInterceptors };



// import axios, { AxiosError, AxiosRequestConfig  } from "axios";
// import { config } from "../../utils/EnvUtil";
// import TokenService, { refreshTokenService } from "./TokenService";


// // Estendendo a interface AxiosRequestConfig
// interface CustomAxiosRequestConfig extends AxiosRequestConfig {
//   _retry?: boolean;
// }


// const apiClient = axios.create({
//   baseURL: config.url.API_URL,
//   headers: { 
//     "Content-Type": "application/json", 
//     "Authorization" : `Bearer ${TokenService.getAccessToken()}`,
//     "Access-Control-Allow-Origin" : "*" ,
//     "Access-Control-Allow-Credential":true
//   },
// });


// //####################  Adicionando lógica para tokens ###################
// let isRefreshing = false;
// let resfreshSubscribers: any[] = [];

// function subscribeTokenResfresh(cb: any){
//   resfreshSubscribers.push(cb);
// }

// function onRefreshed(token: string){
//   resfreshSubscribers.map(cb => cb(token));
// }

// // ########################################################################


// const SetupInterceptors = () => {
//   apiClient.interceptors.request.use(
//     function (config) {
//       // Do something before request is sent
//       // console.log("Pedido: ", config);

//       //Adicionando lógica para tokens
//       const token = TokenService.getAccessToken();
//       if(token){
//         config.headers["Authorization"] = `Bearer ${token}`;
//       }
//       return config;
//     },
//     function (error) {
//       // Do something with request error
//       // console.log("Erro no pedido: ", error);
//       return Promise.reject(error);
//     }
//   );

//   // Add a response interceptor
//   apiClient.interceptors.response.use(
//     (response) => response, 
//     async (error: AxiosError) => {
//       // Any status codes that falls outside the range of 2xx cause this function to trigger
//       // Do something with response error

//       const { config, response } = error;
//       const originalRequest = config as CustomAxiosRequestConfig;

//       //Modificado para fazer o refresh após o acessToken dar errrado, fazer login com o refreshToken
//       // if (error.response?.status === 401) {
//       if (error.response?.status === 401 && !originalRequest._retry) {
         
        
//         // ############################### Adicionando lógica para refreshTokens #########################################################
//         originalRequest._retry = true;

//         if(!isRefreshing)
//         {
//           isRefreshing = true;
//           try {
//             const accessToken = TokenService.getAccessToken();
//             const refreshToken = TokenService.getRefreshToken();
            
//             const tokens = {
//               accessToken: accessToken,
//               refreshToken: refreshToken,
//             };           
             
//             if (accessToken && refreshToken) {
//               await refreshTokenService(tokens);
//               const newAccessToken = TokenService.getAccessToken();

//               if (newAccessToken) {
//                 apiClient.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
//                 isRefreshing = false;
//                 onRefreshed(newAccessToken);
//                 return apiClient(originalRequest);
//               }
//             }

//           } catch (err) {
//             TokenService.removeAccessToken();
//             TokenService.removeRefreshToken();
//             window.location.href = window.location.protocol + "//" + window.location.host + "/login";

//             return Promise.reject(err);
//           }


//         }  else {
//           return new Promise((resolve, reject) => {
//             subscribeTokenResfresh((token: string) => {
//               if (!token) {
//                 reject(error);
//                 return;
//               }
//               originalRequest.headers["Authorization"] = `Bearer ${token}`;
//               resolve(apiClient(originalRequest));
//             });
//           });
//         }
//       }

//       console.log('response', response)

//       // Caso o status não seja 401 ou outra falha
//       if (response?.status !== 401) {
//         window.alert("Error performing request, ocorreu um erro");
//       }

//       window.alert("Error performing request");
//       return Promise.reject(error);
//     }
//   );
// }

// const { get, patch, post, put, delete: destroy } = apiClient;
// export { get, patch, post, put, destroy, SetupInterceptors };
