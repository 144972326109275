// MediaContext.js
import React, { createContext, useContext, useState } from "react";

const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const [mediaType, setMediaType] = useState(null);

  return (
    <MediaContext.Provider value={{ mediaType, setMediaType }}>
      {children}
    </MediaContext.Provider>
  );
};

export const useMedia = () => {
  return useContext(MediaContext);
};
